import { Routes, Route, useLocation } from "react-router-dom"
import Perfil from "./components/perfil/Perfil"
import Login from './components/usuari/Login';
import ForgotPassword from "./components/usuari/ForgotPassword";
import RecoverPassword from "./components/usuari/RecoverPassword";
import Register from "./components/usuari/Register";
import AvisLegal from "./components/usuari/AvisLegal";
import PrivacyPolicy from "./components/usuari/PrivacyPolicy";
import AfegirPersona from "./components/usuari/AfegirPersona";
import LinkInvalid from "./components/LinkInvalid";
import Maintenance from "./components/Maintenance";
import Header from "./components/layout/Header"
import Footer from "./components/layout/Footer"
import Aside from "./components/layout/Aside"
import { useCookies } from 'react-cookie';
import Home from "./components/Home"
import Comandes from "./components/comandes/Comandes"
import NovaComanda from "./components/comandes/NovaComanda"
import EditarComanda from "./components/comandes/EditarComanda"
import DetallsComanda from "./components/comandes/DetallsComanda"
import HorariComanda from "./components/comandes/HorariComanda"
import CertificatsDrets from "./components/certificatsDrets/CertificatsDrets"
import NouCertificatDret from "./components/certificatsDrets/NouCertificatDret"
import ValidarCertificatDret from "./components/certificatsDrets/ValidarCertificatDret"
import ContactUs from "./components/ContactUs"
import { useEffect, useState } from "react";
import instancePromise from './components/axiosInstance';
import ReactGa from 'react-ga';
import AocResponse from "./components/usuari/AocResponse";

function App() {
    const [cookies, setCookie] = useCookies(['auth_token']);
    const estat = useLocation().state
    const [maintenance, setMaintenance] = useState(false)

    useEffect(() => {
        ReactGa.initialize('G-1RCSM9Y5XD')
        ReactGa.pageview(window.location.pathname)
        instancePromise.then(instance => {
            instance
                .get(`/admin/maintenance/`)
                .then(response => {
                    setMaintenance(response.data)
                })
        });
        if (cookies.auth_token != null) {
            instancePromise.then(instance => {
                instance
                    .get(`/usuari/check_token/`,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + cookies.auth_token
                            }
                        }
                    )
                    .then(() => {
                    })
                    .catch(error => {
                        if (error.code !== "ERR_NETWORK") {
                            setCookie('auth_token', '', { path: '/', maxAge: 0 });
                        }
                    });
            });
        }
    }, [cookies, setCookie])

    return (<>
        {maintenance ? (<Maintenance />) : (
            <Routes>
                <Route path="/" element={<>{cookies.auth_token != null ? (<div className="wrapper"><Header /><Aside /><Home /><Footer /></div>) : (<Login />)}</>} />
                <Route path="profile" element={<>{cookies.auth_token != null ? (<div className="wrapper"><Header /><Aside /><Perfil /><Footer /></div>) : (<Login />)}</>} />
                <Route path="comandes" element={<>{cookies.auth_token != null ? (<div className="wrapper"><Header /><Aside /><Comandes /><Footer /></div>) : (<Login />)}</>} />
                <Route path="comandes/crear" element={<>{cookies.auth_token != null ? (<div className="wrapper"><Header /><Aside /><NovaComanda /><Footer /></div>) : (<Login />)}</>} />
                <Route path="comandes/editar" element={<>{cookies.auth_token != null ? (estat != null ? (<div className="wrapper"><Header /><Aside /><EditarComanda /><Footer /></div>) : (<LinkInvalid />)) : (<Login />)}</>} />
                <Route path="comandes/detalls" element={<>{cookies.auth_token != null ? (estat != null ? (<div className="wrapper"><Header /><Aside /><DetallsComanda /><Footer /></div>) : (<LinkInvalid />)) : (<Login />)}</>} />
                <Route path="comandes/horari" element={<>{cookies.auth_token != null ? (estat != null ? (<div className="wrapper"><Header /><Aside /><HorariComanda /><Footer /></div>) : (<LinkInvalid />)) : (<Login />)}</>} />
                <Route path="certificats-drets" element={<>{cookies.auth_token != null ? (<div className="wrapper"><Header /><Aside /><CertificatsDrets /><Footer /></div>) : (<Login />)}</>} />
                <Route path="certificats-drets/crear" element={<>{cookies.auth_token != null ? (<div className="wrapper"><Header /><Aside /><NouCertificatDret /><Footer /></div>) : (<Login />)}</>} />
                <Route path="certificats-drets/validar" element={<ValidarCertificatDret />} />
                <Route path="contact" element={<>{cookies.auth_token != null ? (<div className="wrapper"><Header /><Aside /><ContactUs /><Footer /></div>) : (<Login />)}</>} />
                <Route path="avis-legal" element={<AvisLegal />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="afegir-persona" element={<>{cookies.auth_token != null ? (<AfegirPersona />) : (<Login />)}</>} />
                <Route path="login" element={<Login />} />
                <Route path="aocresponse" element={<AocResponse />} />
                <Route path="account/forgot-password" element={<ForgotPassword />} />
                <Route path="account/recover-password" element={<RecoverPassword />} />
                <Route path="register" element={<Register />} />
                <Route path="*" element={<LinkInvalid />} />
            </Routes>
        )}
    </>
    )
}

export default App