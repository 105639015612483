import React, { useState } from 'react';
import instancePromise from '../axiosInstance';
import { useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom"
import { handleError, alerta } from '../Global'
import '../../table.css'
import './CertificatsDrets.css'

export default function CertificatsDrets() {
    const navigate = useNavigate();
    const [cookies] = useCookies(['auth_token']);

    document.title = "Certificats de drets - Pantà de Riudecanyes"

    var [tePersonesAmbDrets, setTePersonesAmbDrets] = useState(true)
    var [certificatsDrets, setCertificatsDrets] = useState([])
    var [carregant, setCarregant] = useState(true)

    useEffect(() => {
        loadCertificatsDrets();
        loadPersones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies, navigate])

    function loadCertificatsDrets() {
        setCertificatsDrets([])

        instancePromise.then(instance => {
            instance
                .get(`/certificat-dret`, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setCertificatsDrets(response.data)
                    setCarregant(false)
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }

    function loadPersones() {
        instancePromise.then(instance => {
            instance
                .get(`/usuari/persona-amb-drets`, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setTePersonesAmbDrets(response.data.length !== 0)
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }

    function downloadPDF(event, id) {
        //var btnDownload = document.getElementById("btnDownload")
        event.target.disabled = true

        instancePromise.then(instance => {
            instance
                .get(`/certificat-dret/` + certificatsDrets[id].codi, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    },
                    responseType: 'arraybuffer'
                })
                .then(response => {
                    event.target.disabled = false

                    const byteArray = new Uint8Array(response.data);
                    const file = new Blob([byteArray], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = certificatsDrets[id].codi + '.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => {
                    event.target.disabled = false
                    if (error.code === "ERR_NETWORK") {
                        alerta("Error de connexió")
                    } else {
                        if (error.response.status === 401) {
                            navigate("/login")
                        } else {
                            alerta(new TextDecoder().decode(error.response.data));
                        }
                    }
                });
        });
    }

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Certificats de drets</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item active">Certificats de drets</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                {!tePersonesAmbDrets ? (
                    <>
                        <div className="alert alert-info">
                            <h5><i class="icon fas fa-info"></i>Per sol·licitar un nou certificat de dret cal afegir un comuner.</h5>
                            <div>Pot afegir un comuner fent clic al següent <b><a href="/afegir-persona">enllaç</a></b>.</div>
                        </div>
                    </>
                ) : (
                    <div className="card card-primary card-outline">
                        <div className="card-body">
                            <button className="btn btn-primary" onClick={() => navigate("crear")}>
                                <h4><i className="fas fa-plus-circle" /><span> Nou certificat de dret</span></h4>
                            </button>
                        </div>
                    </div>
                )}
                <div className="card">
                    <div className="card-header bg-dark">
                        <h4>Certificats de drets realitzats</h4>
                    </div>
                    {carregant ? (
                        <main className="hold-transition login-page">
                            <i className="fas fa-3x fa-sync-alt fa-spin"></i>
                            <div className="text-bold pt-2">Carregant...</div>
                        </main>
                    ) : (
                        <>
                            <div className="card-body p-0">
                                <table className="resp">
                                    <thead>
                                        <tr>
                                            <th>Data</th>
                                            <th>Número</th>
                                            <th>Tipus</th>
                                            <th>Persona</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="colCertificats">
                                        {certificatsDrets.length === 0 ? (
                                            <tr>
                                                <td colSpan={11}>Cap certificat de dret sol·licitat</td>
                                            </tr>
                                        ) : (
                                            certificatsDrets.map((certificatDret, index) => (
                                                <tr key={index}>
                                                    <td>{certificatDret.ts}</td>
                                                    <td>{certificatDret.numero}</td>
                                                    {certificatDret.tipus_certificat === 1 ? (
                                                        <td>Per tramitar una herència</td>
                                                    ) : (<td>De titularitat</td>)}
                                                    <td>{certificatDret.persona.nom + " " + certificatDret.persona.cognom1 + " " + certificatDret.persona.cognom2}</td>
                                                    <td>
                                                        <button className="fas fa-download" style={{ border: 0 }} onClick={(e) => downloadPDF(e, index)}></button>
                                                    </td>
                                                </tr>
                                            )))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </section >
        </div >
    );
}