import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import instancePromise from '../axiosInstance';
import jwt_decode from "jwt-decode";
import { alerta, handleError } from '../Global';

export default function AocResponse() {
    const location = useLocation();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['aocToken']);

    let [error, setError] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        let code = params.get('code');
        console.log("Code: " + code)

        if (code !== null) {
            const requestBody = new URLSearchParams();
            requestBody.append('code', code);
            requestBody.append('client_id', 'tramits.pantaderiudecanyes.cat');
            requestBody.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
            requestBody.append('redirect_uri', process.env.REACT_APP_REDIRECT_URI_AOC);
            requestBody.append('grant_type', 'authorization_code');

            let VALidGetTokenURL = ""
            let VALidGetUserInfoURL = ""
            let VALidLogoutURL = ""
            if (process.env.NODE_ENV === 'development') {
                VALidGetTokenURL = "/o/oauth2/token"
                VALidLogoutURL = "/o/oauth2/logout"
                VALidGetUserInfoURL = "/serveis-rest/getUserInfo"
            } else if (process.env.NODE_ENV === 'production') {
                VALidGetTokenURL = process.env.REACT_APP_FRONTEND + `/VALidGetToken.php`
                VALidLogoutURL = process.env.REACT_APP_FRONTEND + `/VALidLogout.php`
                VALidGetUserInfoURL = process.env.REACT_APP_FRONTEND + `/VALidGetUserInfo.php`
            }

            axios
                .post(VALidGetTokenURL, requestBody, {
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': "application/x-www-form-urlencoded"
                    }
                })
                .then(response => {
                    console.log("Data from /o/oauth2/token: " + response.data)
                    console.log("aocToken: " + response.data.access_token)
                    let aocToken = response.data.access_token
                    let age = response.data.expires_in
                    setCookie('aocToken', aocToken, { path: '/', maxAge: age })

                    axios
                        .get(VALidLogoutURL + `?token=` + aocToken)
                        .then(() => {

                            axios
                                .get(VALidGetUserInfoURL + `?AccessToken=` + aocToken)
                                .then(response => {
                                    console.log("Data from /serveis-rest/getUserInfo: " + response.data)
                                    let status = response.data.status

                                    if (status === "ok") {
                                        instancePromise.then(instance => {
                                            instance
                                                .post(`/usuari/login-valid`,
                                                    {
                                                        NIF: response.data.identifier,
                                                        method: response.data.method,
                                                        assuranceLevel: getAssuranceLevel(response.data.assuranceLevel),
                                                        username: ((response.data.name ?? "") + (response.data.surnames ? " " + response.data.surnames : ""))
                                                    })
                                                .then(response => {
                                                    console.log("Data from /usuari/login-valid: " + response.data)
                                                    let token = response.data
                                                    let decoded = jwt_decode(response.data);
                                                    let tokenAge = decoded.exp - decoded.iat;
                                                    setCookie('auth_token', token, { path: '/', maxAge: tokenAge })

                                                    navigate("/")
                                                    window.location.reload();
                                                })
                                                .catch(error => {
                                                    setError(true)
                                                    handleError(error)
                                                });
                                        });
                                    } else {
                                        setError(true)
                                        alerta(response.data.error)
                                    }
                                })
                                .catch(() => {
                                    setError(true)
                                });
                        })
                        .catch(() => {
                            setError(true)
                        });
                })
                .catch(() => {
                    setError(true)
                });
        }
        else {
            setError(true)
        }
    }, [location.search, setCookie, navigate]);

    function getAssuranceLevel(assuranceLevel) {
        if (assuranceLevel === "low") return 2;
        else if (assuranceLevel === "substantial") return 3;
        else if (assuranceLevel === "high") return 4;
        else return 1;
    }

    return (
        <>
            {error ? (<main className="hold-transition login-page">
                <div className="login-box">
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                        </div>
                        <div className="card-body">
                            <h3 className="login-box-msg"><i className="fas fa-exclamation-circle"></i> Error</h3>

                            <div>No s'ha pogut iniciar sessió. Provi un altre mètode</div>

                            <a href="/login" className="text-center">Iniciar sessió</a>
                        </div>
                    </div>
                </div>
            </main>
            ) : (
                <main className="hold-transition login-page">
                    <i className="fas fa-3x fa-sync-alt fa-spin"></i>
                    <div className="text-bold pt-2">Carregant...</div>
                </main>)}
        </>
    );
};
