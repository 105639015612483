import React from "react";
import packageJson from '../../../package.json';

export default function Footer() {
    return (
        <footer className="main-footer">
            <strong>
                Comunitat de regants del Pantà de Riudecanyes.
            </strong>

            <div className="float-right d-none d-sm-inline-block">
                <b>Versió</b> {packageJson.version}
            </div>
        </footer>
    );
}
